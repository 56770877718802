import { PrivateCallContextData } from "@/context/AppContext";
import {
  Call_Emit_Response,
  Call_Start_Response,
  Caller,
} from "@/utils/call-service.types";
import { ToastDataType, ToastType } from "@/utils/common.types";
import { Dispatch, SetStateAction } from "react";
import { Socket } from "socket.io-client";
import TRTC from "trtc-sdk-v5";
import { captialize } from "@/utils/helper";
import { SOCKET_ERROR_TYPES } from "@/modules/Astro/Strings";

//! Private Call Emits ----->>

//! Start Call Function ---->>
const startCall = async (
  trtcClient: TRTC,
  user: Caller,
  onSuccess: () => void,
  setToastData: Dispatch<SetStateAction<ToastDataType>> | undefined,
  socket: Socket | null | undefined,
  setPrivateCallData: (data: object) => void
) => {
  try {
    //! call invite Event Emit  ------
    await trtcClient.startLocalAudio();
    await trtcClient?.stopLocalAudio?.();
    await trtcClient.startLocalVideo();
    await trtcClient?.stopLocalVideo?.();
    socket?.emit(
      "fe_private_call_send_invite",
      { userId: user?.userId },
      (response: Call_Emit_Response) => {
        // console.log("response ack", response);
        if (response?.message === "success") {
          onSuccess();
        } else if (response?.error) {
          if (response.error.type === SOCKET_ERROR_TYPES.INSUFFICIENT_BALANCE) {
            setPrivateCallData({ insufficientBalancePopup: true });
            return;
          }
          setToastData &&
            setToastData({
              message: captialize(response.error.message),
              type: ToastType.ERROR,
              visible: true,
            });
        }
      }
    );
    return;
  } catch (error) {
    console.log(error);
  }
};

//! End Call Function --
const endCall = async (trtcClient: TRTC, socket: Socket | null | undefined) => {
  try {
    await trtcClient?.stopLocalAudio?.();
    await trtcClient?.stopLocalVideo?.();
    const messageObject = {};
    //! call end Event Emit  ------
    socket?.emit("fe_private_call_end", messageObject, (respones: object) => {
      // console.log("response ack", respones);
    });
  } catch (error) {
    console.log(error);
  }
};

//! Mute RemoteAudio Function ---
const muteRemoteAudio = async (
  trtcClient: TRTC,
  isMuted: boolean,
  socket: Socket | null | undefined
) => {
  try {
    socket?.emit(
      "fe_private_call_mute_myself",
      { isMuted },
      (respones: object) => {
        // console.log("response ack", respones);
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export { startCall, endCall, muteRemoteAudio };
