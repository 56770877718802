import styles from "./astrologer.module.scss";
import Image from "next/image";
import { ASTRO_TAGS, AstroImages } from "../../../modules/Astro/constants";
import {
  formatName,
  getRandomNumberBasedOnUsername,
} from "../../../utils/helper";
import { RoomResponse, sessionType } from "../../../types/common.types";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "@/context/AppContext";
import { startCall } from "@/services/call.service";
import { RUPEE_WHITE_ICON } from "@/utils/imageRelativePaths";

const getRating = (index: number) => {
  const val = index % 3;
  if (val === 0) return 4;
  if (val === 1) return 4.5;
  if (val === 2) return 5;

  return 5;
};

export function AstrologerCard(props: {
  session: RoomResponse;
  handleClick: () => void;
  index: number;
}) {
  const { liveRoom, userProfile } = props.session;
  const context = useContext(AppContext);
  const { token, userId } = context?.auth ?? {};
  const {
    setPopups,
    trtcClient,
    privateCallSocket,
    setPrivateCallData = () => null,
    userData,
    auth,
    setToastData,
    walletBalance,
    privateCallData,
  } = context ?? {};

  const handleCallClick = () => {
    if (!token) {
      setPopups &&
        setPopups({
          showLoginPopup: true,
        });
      return;
    }

    if (
      (userData?.diamonds ?? 0) <= props.session.userProfile.rateInInr &&
      (walletBalance?.astroVCard ?? 0) === 0
    ) {
      setPrivateCallData &&
        setPrivateCallData({
          ...privateCallData,
          insufficientBalancePopup: true,
        });

      return;
    }
    if (userData && auth?.userId && trtcClient && privateCallSocket)
      void startCall(
        trtcClient,
        userProfile,
        () => {
          setPrivateCallData({
            callPopup: true,
            caller: {
              ...userData,
              photo: userData?.profilePic,
              userId: auth?.userId ?? "",
            },
            contentOwner: userProfile,
          });
        },
        setToastData,
        privateCallSocket,
        setPrivateCallData
      );
  };
  return (
    <div
      className={styles["each_astrologer"]}
      key={liveRoom.userId}
      onClick={props.handleClick}
    >
      <div className={styles["profile_cont"]}>
        <div className={styles["profile_img_cont"]}>
          <Image
            className={styles["profile_img"]}
            width={134}
            height={134}
            src={userProfile.photo}
            alt={"astrologer"}
          />
          <div className={styles["profile_status"]} />
        </div>
        <div className={styles["profile_tags"]}>
          <div className={styles["tags"]}>
            {liveRoom.creatorSubCategories.map((tag) => (
              <div key={tag}>{tag}</div>
            ))}
          </div>
          <div className={styles["divider"]} />
          <div className={styles["profile_rate"]}>
            <Image
              src={RUPEE_WHITE_ICON}
              className={styles["rupees"]}
              width={14}
              height={14}
              alt={"rupee-icon"}
            />{" "}
            {userProfile?.rateInInr?.toFixed(2)}/min{" "}
            {false && (
              <div className={styles["strike_rate"]}>
                {" "}
                (₹ {userProfile.rate * 1.2}/min)
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles["expert_details"]}>
        <div className={styles["name"]}>
          {formatName(userProfile.name)}{" "}
          <Image className={styles["badge"]} src={AstroImages.BADGE} alt={""} />
        </div>
        <div className={styles["lang"]}>
          <Image
            src={AstroImages.TRANSLATE}
            className={styles["icon"]}
            alt={""}
          />
          {liveRoom.languages.map((lang) => (
            <div className={styles["lang_div"]} key={lang}>
              {lang}
              <div />
            </div>
          ))}
        </div>
        <div className={styles["exp"]}>
          <Image
            src={AstroImages.EXPERIENCE}
            className={styles["icon"]}
            alt={""}
          />
          {getRandomNumberBasedOnUsername(userProfile?.userName)} Years
          Experience
        </div>
        <div className={styles["stars_cont"]}>
          {new Array(5).fill(0).map((item, idx) => (
            <span
              className={
                idx < getRating(props.index + 1)
                  ? styles["selected"]
                  : styles["not_selected"]
              }
              key={idx}
            >
              &#9733;
            </span>
          ))}
          <p>({getRating(props.index + 1)})</p>
        </div>

        <div className={styles["action_cont"]}>
          <button>
            <Image src={AstroImages.CHAT} alt={""} /> CHAT
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleCallClick();
            }}
          >
            <Image src={AstroImages.CALL} alt={""} /> CALL
          </button>
        </div>
      </div>
    </div>
  );
}
