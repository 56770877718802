"use client";

import { useCallback } from "react";
import { useRouter } from "next/navigation";
import { InfiniteScroll, InfiniteScrollLoader } from "chingari-components";

import styles from "./PostsContainer.module.scss";
import { RoomResponse, ROOM_TYPE } from "@/types/common.types";
import { logEvent } from "@/modules/Astro/helper";
import { EventNames } from "@/modules/Astro/Strings";
import PostCard from "@/modules/Astro/components/PostCard";
import { useParams } from "next/navigation";
import { ROOM_URL } from "@/utils/constants/constants";
import { AstrologerCard } from "@/common/components/AstrologerCard";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import ChatCard from "@/modules/Astro/Chat/components/ChatCard";

interface PostsContainerProps {
  hasMore: boolean;
  data: Array<RoomResponse>;
  userId?: string;
  category: string;
  subCategory: string;
  fetchData: () => Promise<void>;
}

export const handleCardClick = (userId: string, router: AppRouterInstance) => {
  router.push(`/${userId}`);
};

const PostsContainer = ({
  hasMore,
  data,
  userId,
  category,
  subCategory,
  fetchData,
}: PostsContainerProps) => {
  const router = useRouter();
  const { roomType = "" } = useParams();

  const onPostCardClick = useCallback((roomId: number) => {
    // here if live is there then we will go to feed page on press
    // otherwise for audio we will go to /room/${id} page
    if (roomType === ROOM_TYPE.LIVE) {
      logEvent(EventNames.RoomIconClicked, {
        source: ROOM_TYPE.LIVE,
        userId,
      });
      void router.push(
        `/${roomType}/feed?roomId=${roomId}&category=${category}&subCategory=${subCategory}`
      );
    } else {
      logEvent(EventNames.RoomIconClicked, {
        source: ROOM_TYPE.AUDIO,
        userId,
      });
      void router.push(`${ROOM_URL}/${roomId}`);
    }
  }, []);

  const renderCard = (item: RoomResponse, index: number) => {
    if (roomType === ROOM_TYPE.LIVE)
      return (
        <PostCard
          {...item}
          className={styles.postCardWrapper}
          key={item.liveRoom.id}
          onClick={() => onPostCardClick(item.liveRoom.id)}
        />
      );

    if (roomType === ROOM_TYPE.CHAT)
      return (
        <ChatCard
          key={item.liveRoom.id}
          session={item}
          handleClick={() => handleCardClick(item.userProfile.userName, router)}
          index={index}
        />
      );

    return (
      <AstrologerCard
        key={item.liveRoom.id}
        session={item}
        handleClick={() => handleCardClick(item.userProfile.userName, router)}
        index={index}
      />
    );
  };

  return (
    <div
      className={`${styles.postsContainerWrapper} ${
        styles[roomType as string]
      }`}
    >
      <InfiniteScroll
        hasMore={hasMore}
        next={fetchData}
        loader={<InfiniteScrollLoader />}
        bottomDivClassname={styles.bottomSrollDiv}
      >
        {data?.map((item, index) => renderCard(item, index))}
      </InfiniteScroll>
    </div>
  );
};

export default PostsContainer;
