import { Config } from "../config";
import { getMainDomain } from "../helper";

let CDN_URL = Config.Cdn_Url;

if (
  typeof window !== "undefined" &&
  window?.location?.hostname !== "localhost"
) {
  CDN_URL = Config.Cdn_Url?.replace(
    getMainDomain(Config.Cdn_Url),
    getMainDomain(window?.location?.hostname)
  );
}

const levelIconFolderPath = `/assets/icons/level`;
const levelIconRelativePath = `${CDN_URL}/levels`;
const leaderBoardRelativePath = `${CDN_URL}/leaderboard`;
const liveRoomRelativePath = `${CDN_URL}/liveroom`;
const earningsRelativePath = `${CDN_URL}/earnings`;
const DEV_earningsRelativePath = "/assets";
const astroIconFolderPath = `/assets/icons/astro`;

const allLevelIcons = (): { [key: string]: string } =>
  [...(Array(13) as [])].reduce(
    (acc: { [key: string]: string }, _elem, index: number) => {
      let idx = index;
      if (idx === 11) idx = 20;
      if (idx === 12) idx = 100;
      acc[`level${idx}`] = `${levelIconFolderPath}/allLevels/${idx}.svg`;
      acc[`badge${idx}`] = `${levelIconFolderPath}/badges/${idx}.svg`;
      return acc;
    },
    {}
  );

export const Images: { [key: string]: string } = {
  iconBack: "/assets/icons/back.svg",
  iconBackWhite: "/assets/icons/back-white.svg",
  iconCross: "/assets/icons/cross.svg",
  iconProfile: "/assets/icons/profile.svg",
  iconProfile1: "/assets/icons/profileIcon.svg",
  iconStar: `${leaderBoardRelativePath}/star.svg`,
  iconDiamond: `${leaderBoardRelativePath}/diamond.svg`,
  iconTwitterScore: "/assets/icons/twitter-score-badge.svg",
  iconBean: `/assets/icons/beanIcon.svg`,
  iconInfo: `/assets/icons/info.svg`,
  iconInfoWhite: `/assets/icons/info-white.svg`,
  iconInfoBlack: `assets/icons/infoBlack.svg`,
  iconInfoRed: `assets/icons/info-icon.svg`,
  iconAnonymous: `${leaderBoardRelativePath}/anonymous.svg`,
  iconCrownPlatinum: "/assets/icons/crown-platinum.svg",
  iconCrownGoldNew: "/assets/icons/crown-gold-new.svg",
  iconCrownSilverNew: "/assets/icons/crown-silver-new.svg",
  iconCrownBronzeNew: "/assets/icons/crown-bronze-new.svg",
  iconCrownGold: `${leaderBoardRelativePath}/crown-gold.svg`,
  iconCrownSilver: `${leaderBoardRelativePath}/crown-silver.svg`,
  iconCrownBronze: `${leaderBoardRelativePath}/crown-bronze.svg`,
  iconLoader: `/assets/icons/loader.svg`,
  levelCardBanner: "/assets/card_banner.svg",
  iconCallPrice: "/assets/icons/call_price.svg",
  iconEditPrice: "/assets/icons/edit_price_pencil.svg",
  iconForward: "/assets/icons/forwardArrow.svg",
  iconWarning: "/assets/icons/warning.svg",
  ...allLevelIcons(),
  iconCalendar: `/assets/icons/calendar-white.svg`,
  iconGariRound: "/assets/icons/gari.svg",
  iconGuac: "/assets/icons/guac.svg",
  iconJup: "/assets/icons/jup.svg",
  iconWen: "/assets/icons/wen.svg",
  iconFireOrange: "/assets/icons/fire-orange.svg",
  iconDoodoo: "/assets/icons/doodoo.svg",
  iconChewy: "/assets/icons/chewy.webp",

  // livestream
  iconFavIcon: `${liveRoomRelativePath}/fav.png`,
  iconChingariLogo: `${liveRoomRelativePath}/chingari-logo.webp`,
  iconChingariFireLogo: `${liveRoomRelativePath}/chingari-fire-icon.svg`,
  iconUser: `${liveRoomRelativePath}/user.svg`,
  iconArrow: `${liveRoomRelativePath}/arrow.svg`,
  iconParty: `${liveRoomRelativePath}/party.svg`,
  iconPlayStore: `${liveRoomRelativePath}/play-store.webp`,
  iconAppleStore: `${liveRoomRelativePath}/apple-store.webp`,
  iconDefaultRoom: `${liveRoomRelativePath}/default-room.webp`,

  // earning

  infoIcon: `${earningsRelativePath}/Icons/info.svg`,
  iconBeanEarning: `${earningsRelativePath}/Icons/Bean_icon.svg`,
  iconRightCarrot: `/assets/icons/rightCarrotPurple.svg`,
  iconRightCarrotBlack: `/assets/icons/rightCarrotBlack.svg`,
  iconInvertedTriangle: `${earningsRelativePath}/Icons/invertedTriangle.svg`,
  iconDanger: `${earningsRelativePath}/Icons/danger.svg`,
  iconVerified: `${earningsRelativePath}/Icons/verified.svg`,
  iconGari: `${earningsRelativePath}/Icons/gari_logo.svg`,
  iconPaytm: `/assets/icons/Paytm_logo.svg`,
  iconClose: `${earningsRelativePath}/Icons/iconClose.svg`,
  iconCloseBlack: `/assets/icons/iconCloseBlack.svg`,
  iconHelp: `${earningsRelativePath}/Icons/Help.svg`,
  walletIcon: `/assets/icons/link-wallet-icon.svg`,

  // gifts

  giftSentBg: "/assets/images/gifts_sent.png",
  giftReceivedBg: "/assets/images/gifts_receive.png",
  giftImage: "/assets/images/gift.png",

  // diamonds
  diamondLarge: "/assets/icons/diamond_large.svg",
  filter: "/assets/icons/filterNew.svg",
  upArrow: "/assets/icons/upArrow.svg",
  downArrow: "/assets/icons/downArrow.svg",
  upArrowBlack: "/assets/icons/upArrowNew.svg",
  downArrowBlack: "/assets/icons/downArrowNew.svg",
  ballon: "/assets/backgrounds/Ballon.svg",

  // faq
  faqIcon: "/assets/images/faq.png",
  faqDownArrow: `/assets/icons/down_arrow.svg`,
  faqUpArrow: `/assets/icons/up_arrow.svg`,

  // creator form
  warningIcon: "/assets/icons/warning1.svg",
  successImg: "/assets/images/creator-form-success.svg",

  //astro
  leo: `${astroIconFolderPath}/Leo.svg`,
  aries: `${astroIconFolderPath}/Aries.svg`,
  taurus: `${astroIconFolderPath}/Taurus.svg`,
  cancer: `${astroIconFolderPath}/Cancer.svg`,
  aquarius: `${astroIconFolderPath}/Aquarius.svg`,
  libra: `${astroIconFolderPath}/Libra.svg`,
  scorpio: `${astroIconFolderPath}/Scorpio.svg`,
  sagittarius: `${astroIconFolderPath}/Sagittarius.svg`,
  capricorn: `${astroIconFolderPath}/Capricorn.svg`,
  pisces: `${astroIconFolderPath}/Pisces.svg`,
  gemini: `${astroIconFolderPath}/Gemini.svg`,
  virgo: `${astroIconFolderPath}/Virgo.svg`,
  name_card_background: `/assets/backgrounds/Name-Card.svg`,
  character: `${astroIconFolderPath}/alien.svg`,
  physical: `${astroIconFolderPath}/dumbell.svg`,
  family: `${astroIconFolderPath}/hearts-two.svg`,
  education: `${astroIconFolderPath}/briefcase.svg`,
  health: `${astroIconFolderPath}/heart-wave.svg`,
  plane: `${astroIconFolderPath}/plane.svg`,
  diamond: `${astroIconFolderPath}/diamond.svg`,
  face_smile: `${astroIconFolderPath}/face-smile.svg`,
  search: `${astroIconFolderPath}/search-ic.svg`,
  sun: `${astroIconFolderPath}/sun.svg`,
  moon: `${astroIconFolderPath}/moon.svg`,
  astroImage1: `${astroIconFolderPath}/astro-image-1.svg`,
  astroImage2: `${astroIconFolderPath}/astro-image-2.svg`,
  astroImage3: `${astroIconFolderPath}/astro-image-3.svg`,
  diamondPurple: `${astroIconFolderPath}/diamond-purple.svg`,
  redDot: `${astroIconFolderPath}/red-dot.svg`,
  translate: `${astroIconFolderPath}/Translate.svg`,
  verifiedBadge: `${astroIconFolderPath}/verified-badge.svg`,
  astroImage4: `${astroIconFolderPath}/astro-image-4.svg`,
  astroImage5: `${astroIconFolderPath}/astro-image-5.svg`,
  kundliImage1: `${astroIconFolderPath}/kundli-image-1.svg`,
  kundliImage2: `${astroIconFolderPath}/kundli-image-2.svg`,
  kundliImage3: `${astroIconFolderPath}/kundli-image-3.svg`,
  IconSun: `${astroIconFolderPath}/sun-white.svg`,
  IconPanchang: `${astroIconFolderPath}/panchang-icon.svg`,
  IconKundli: `${astroIconFolderPath}/kundli-icon.svg`,
  IconFreeKundli: `${astroIconFolderPath}/hearts-white.svg`,
  calendar: `${astroIconFolderPath}/calendar.svg`,
  contacts: `${astroIconFolderPath}/contacts.svg`,
  close: `${astroIconFolderPath}/cross.svg`,
  bride: `${astroIconFolderPath}/bride.svg`,
  bridegroom: `${astroIconFolderPath}/bridegroom.svg`,
  people_two: `${astroIconFolderPath}/people-two.svg`,
  saturn_card: `${astroIconFolderPath}/saturn-card.svg`,
  delete: `${astroIconFolderPath}/bin.svg`,
  pencil: `${astroIconFolderPath}/pencil.svg`,
  call: `${astroIconFolderPath}/phone.svg`,
  vcard: `${astroIconFolderPath}/vcard-image.svg`,
  astrologerPhoto: `${astroIconFolderPath}/astrologer-photo.svg`,
  funnel: `${astroIconFolderPath}/filter-funnel.svg`,
  diamondWhite: `${astroIconFolderPath}/diamond-white.svg`,
  greenDot: `${astroIconFolderPath}/green-dot.svg`,
  checkVerified: `${astroIconFolderPath}/check-verified.svg`,
  IC_VIEWERS: `${astroIconFolderPath}/viewers.svg`,
  iconPlus: `${astroIconFolderPath}/plus.svg`,
  // city contest
  cityVideo:
    "https://media.chingari.io/uploads/notifications/82fd3bd7-3590-422f-905d-805e54728a02_.mp4",
  fullscreen: "/assets/icons/fullscreen.svg",
  IC_BRIEFCASE: `${astroIconFolderPath}/briefcase.svg`,
  IC_LANGUAGES: `${astroIconFolderPath}/language-letters.svg`,
  starFilled: `${astroIconFolderPath}/star-1.svg`,
  starUnfilled: `${astroIconFolderPath}/Star-2.svg`,
  chat: `${astroIconFolderPath}/chat.svg`,
  chatPurple: `${astroIconFolderPath}/chat-purple.svg`,

  //wallet
  getTag: "/assets/icons/get-tag.svg",
  popularTag: "/assets/icons/popular-tag.svg",
};
